import qs from 'querystring';

import * as Ably from 'ably';
import axios from 'axios';
import moment from 'moment';
import Router from 'next/router';
import React, { Component } from 'react';
import shortid from 'shortid';

import abilities from './abilities';
import api from './api-client';
import logger from './logger';
import Loader from '../components/partial/Loader';
import NotActive from '../components/partial/NotActive';
import { toggleSnack } from '../redux/actions';

interface Props extends GlobalProps {
  [key: string]: any;
}

interface State {
  userSocketLoading: boolean;
  badgesSocketLoading: boolean;
}

/**
 * HOC to add extra methods to all components
 */
const publicPaths = ['/login', '/reset-password', '/connect'];

const isMobile = () => {
  // @ts-ignore
  if (process.browser) {
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName('body')[0];
    const width = w.innerWidth || e.clientWidth || g.clientWidth;
    const height = w.innerHeight || e.clientHeight || g.clientHeight;

    return width < 600 || height > width;
  }

  return false;
};
const deviceScreen = () => {
  // @ts-ignore
  if (process.browser) {
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName('body')[0];
    const width = w.innerWidth || e.clientWidth || g.clientWidth;
    const height = w.innerHeight || e.clientHeight || g.clientHeight;

    return { width, height };
  }

  return { width: 0, height: 0 };
};
let lastRout: any = { pathname: '/' };
let routEventHandleInit = false;
let keyEventHandleInit = false;
let refreshBadgesTimer;
let timer;

// caching ably to not make auth. request on each `getChannel` call
const ablySymbol = Symbol.for('ably');
global[ablySymbol] = null;

function getChannel(user) {
  return ch => {
    return new Promise((resolve, reject) => {
      if (global[ablySymbol]) {
        const channel = global[ablySymbol].channels.get(`${user.tid}:${ch}`);
        resolve(channel);
      } else {
        api.rest
          .service('v1/controllers/ably-auth')
          .find({})
          .then(tokenDetails => {
            global[ablySymbol] = new Ably.Realtime({ tokenDetails, echoMessages: false });
            const channel = global[ablySymbol].channels.get(`${user.tid}:${ch}`);
            resolve(channel);
          })
          .catch(reject);
      }
    });
  };
}

function uploadFile(props): any {
  const { isLoading } = props;

  return (blob: any, { folder = 'others', type = 'cdn' }: any = {}): Promise<any> => {
    isLoading(true);
    const ext = blob.name.split('.').pop();
    const fileName = `${moment().format('MM-DD-YYYY')}_${shortid.generate()}.${ext}`;
    // const fileName = blob.name;
    const filePath = `${folder}/${moment().format('MM-DD-YYYY')}/${shortid.generate()}/${fileName}`;
    return api.rest
      .service('v1/storage/get-permitted-url')
      .create({
        file: filePath,
        fileType: type,
        action: 'write',
      })
      .then(({ url, bucket, file }) => {
        const options = { headers: { 'Content-Type': blob.type } };

        return axios.put(url, blob, options).then(() => {
          return api.rest
            .service('v1/storage/confirm-upload')
            .create({
              bucket,
              file,
              fileName,
              size: blob.size,
              hashType: 'sha256',
              hashEncoding: 'hex',
              tag: 'admin-upload',
            })
            .then(upload => {
              isLoading(false);
              return upload;
            });
        });
      })
      .catch(err => {
        isLoading(false);
        throw err;
      });
  };
}

function copyToClipboard(text: string, alertMessage?: string) {
  return async (): Promise<void> => {
    await navigator.clipboard.writeText(text);
    alert(alertMessage || 'Text copied to clipboard');
  };
}

function numberToCurrencyStr(currency: string, nm: number, toCents: boolean = false): string {
  const number = toCents ? Number((Number(nm || 0) / 100).toFixed(2)) : Number(nm || 0);

  if (!currency) return number?.toFixed(2);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency?.toUpperCase(),
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(number);
}

function getCurrencySimbl(currency): string {
  if (!currency) return currency;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency?.toUpperCase(),
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const res = formatter.format(0);

  return res?.split(' ')[1] ? res.split(' ')[0] : res[0];
}

export function extendProps(BaseComponent: any) {
  return function ComponentWrapper(props: Props) {
    const { redux: { state: { user } = { user: null } } = {} } = props;

    // to fix issue with `router.query === undefined` on static version
    if (props.router) {
      props.router.query = qs.parse(props.router.asPath.split('?')[1]);
    }

    // open in new tab on ctr pressed
    if (props.router?.push) {
      props.router.push = opt => {
        if (props?.redux?.state?.cntrlIsPressed) {
          let url = opt?.pathname || opt || '';
          if (opt?.query) url += `?${qs.stringify(opt.query)}`;
          window.open(url, '_blank');
        } else {
          return Router.push(opt);
        }
      };
    }

    return (
      <BaseComponent
        {...props}
        api={api.rest}
        socket={api.socket}
        apiTs={api.restTs}
        socketTs={api.socketTs}
        user={abilities(user)}
        getChannel={getChannel(user)}
        router={props.router}
        uploadFile={uploadFile(props)}
        copyToClipboard={copyToClipboard}
        numberToCurrencyStr={numberToCurrencyStr}
        getCurrencySimbl={getCurrencySimbl}
        isMobile={isMobile}
        isTerminal={isMobile() && user?.terminalViewInMobile}
        deviceScreen={deviceScreen}
        backToLastRout={() => Router.push(lastRout)}
        logger={logger}
      />
    );
  };
}

// TODO: move to _app.js
export function extendPropsForPages(BaseComponent: any): any {
  return class ComponentWrapper extends Component<Props, State> {
    state = {
      userSocketLoading: false,
      badgesSocketLoading: false,
    };

    UNSAFE_componentWillMount() {
      if (!routEventHandleInit) {
        routEventHandleInit = true;
        const mobile = isMobile();
        Router.events.on('routeChangeStart', () => {
          lastRout = {
            pathname: Router.router?.pathname || '/',
            query: Router.router?.query || {},
          };
        });
        Router.events.on('routeChangeComplete', () => {
          const {
            redux: {
              state: { leftDrawer },
            },
          } = this.props;

          window.scroll(0, 0);

          if (mobile && leftDrawer) {
            this.props.toggleLeftDrawer(false);
          }
        });
        // TODO save perv. path for back btn.
      }

      // on production with static site init before mount
      // @ts-ignore
      if (process.browser) {
        this.init().catch(logger.error);
      }
    }

    componentWillUnmount() {
      this.removeListener();
    }

    componentDidMount(): void {
      // @ts-ignore
      if (process.browser && !keyEventHandleInit) {
        keyEventHandleInit = true;
        document?.addEventListener('keydown', e => {
          if (Number(e?.which) === 17 || e.key === 'Meta') {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => this.props.cntrlIsPressed(false), 1000 * 60);
            this.props.cntrlIsPressed(true);
          }
        });
        document?.addEventListener('keyup', e => {
          if (Number(e?.which) === 17 || e.key === 'Meta') {
            if (timer) clearTimeout(timer);
            this.props.cntrlIsPressed(false);
          }
        });
      }
    }

    listener = () => {
      const {
        refreshUser,
        redux: {
          state: { user },
        },
      } = this.props;
      const { userSocketLoading } = this.state;

      if (!userSocketLoading && user && user._id) {
        this.setState({ userSocketLoading: true }, async () => {
          await refreshUser(user._id);
          // use setTimeout to avoid multi refresh in a short period of time
          setTimeout(() => this.setState({ userSocketLoading: false }), 1000);
        });
      }
    };

    refreshBadges = user => {
      const { asyncInitState } = this.props;
      const { badgesSocketLoading } = this.state;

      if (refreshBadgesTimer) return;

      // to prevent running this query more often then every 5s
      refreshBadgesTimer = setTimeout(() => this.refreshBadges(user), 1000 * 60 * 5);

      if (!badgesSocketLoading && user?._id) {
        this.setState({ badgesSocketLoading: true }, () => {
          asyncInitState(abilities(user), ['badges'])
            .then(() => this.setState({ badgesSocketLoading: false }))
            .catch(err => {
              this.setState({ badgesSocketLoading: false });
              logger.error(err);
            });
        });
      }
    };

    initListener = () => {
      this.removeListener();
      api.socket.service('v1/objects/users').on('patched', this.listener);
      api.socket.service('v1/objects/user-preferences').on('patched', this.listener);
      api.socket.service('v1/objects/tenants').on('patched', this.listener);
      api.socket.service('v1/objects/tenant-settings').on('patched', this.listener);

      // api.socket.service('v1/objects/customer-orders').on('created', this.refreshBadges);
      // api.socket.service('v1/objects/customer-orders').on('patched', this.refreshBadges);
      // api.socket.service('v1/objects/customer-invoices').on('created', this.refreshBadges);
      // api.socket.service('v1/objects/customer-invoices').on('patched', this.refreshBadges);
    };

    removeListener = () => {
      if (refreshBadgesTimer) clearTimeout(refreshBadgesTimer);

      api.socket.service('v1/objects/users').removeListener('patched', this.listener);
      api.socket.service('v1/objects/user-preferences').removeListener('patched', this.listener);
      api.socket.service('v1/objects/tenants').removeListener('patched', this.listener);
      api.socket.service('v1/objects/tenant-settings').removeListener('patched', this.listener);

      // api.socket.service('v1/objects/customer-orders').removeListener('created', this.refreshBadges);
      // api.socket.service('v1/objects/customer-orders').removeListener('patched', this.refreshBadges);
      // api.socket.service('v1/objects/customer-invoices').removeListener('created', this.refreshBadges);
      // api.socket.service('v1/objects/customer-invoices').removeListener('patched', this.refreshBadges);
    };

    init = async () => {
      const { redux, login, asyncInitState, router, isLoading } = this.props;

      if (!redux.state.asyncInit && !['/connect'].includes(location?.pathname)) {
        if (!redux.state.user) {
          const accessToken = router.query?.jwt;
          await login(accessToken ? { accessToken, strategy: 'jwt' } : undefined)
            .then(user => {
              if (accessToken) {
                delete router.query.jwt;
                router.replace(
                  {
                    pathname: router.pathname,
                    query: router.query,
                  },
                  undefined,
                  { shallow: true }
                );
              }

              isLoading(false);
              return asyncInitState(abilities(user), ['leftDrawer', 'badges']);
            })
            .catch(err => {
              // if (process.env.ENV !== 'development' && !publicPaths.includes(router.route)) {
              if (!publicPaths.includes(router.route)) {
                location.href = `/login${location.search ? `?redirect=${location.search}` : ''}`;
              } else {
                toggleSnack(err.message);
              }
            });
        }
      } else {
        isLoading(false);
      }

      if (redux.state.user && router.route !== '/setup') {
        this.initListener();
      }
    };

    render() {
      // to fix issue with `router.query === undefined` on static version
      this.props.router.query = qs.parse(this.props.router.asPath.split('?')[1]);

      const {
        router,
        redux: {
          state: { user },
        },
      } = this.props;

      if (!user && !publicPaths.includes(router.route)) {
        return <Loader />;
      }

      if (isMobile() && !router.route?.startsWith('/terminal') && user?.terminalViewInMobile) {
        location.href = '/terminal';
        return <Loader />;
      }

      if (user?._id && publicPaths.includes(router.route)) {
        location.href = '/';
        return <Loader />;
      }

      if (user && (!user.tenant || !user.isActive || !user.tenant.isActive || !user.tenant.subscription.isActive)) {
        // @ts-ignore
        return <NotActive />;
      }

      // open in new tab on ctr pressed
      if (router?.push) {
        router.push = opt => {
          if (this.props?.redux?.state?.cntrlIsPressed) {
            let url = opt?.pathname || opt || '';
            if (opt?.query) url += `?${qs.stringify(opt.query)}`;
            window.open(url, '_blank');
          } else {
            return Router.push(opt);
          }
        };
      }

      return (
        <BaseComponent
          {...this.props}
          api={api.rest}
          socket={api.socket}
          apiTs={api.restTs}
          socketTs={api.socketTs}
          user={abilities(this.props?.redux?.state?.user)}
          router={router}
          getChannel={getChannel(user)}
          uploadFile={uploadFile(this.props)}
          copyToClipboard={copyToClipboard}
          numberToCurrencyStr={numberToCurrencyStr}
          getCurrencySimbl={getCurrencySimbl}
          isMobile={isMobile}
          isTerminal={isMobile() && user?.terminalViewInMobile}
          deviceScreen={deviceScreen}
          backToLastRout={() => Router.push(lastRout)}
          logger={logger}
        />
      );
    }
  };
}
