import auth from '@feathersjs/authentication-client';
import feathers from '@feathersjs/feathers';
import * as feathersRestClient from '@feathersjs/rest-client';
import socketio from '@feathersjs/socketio-client';
import ax from 'axios';
import axiosRetry from 'axios-retry';
import io from 'socket.io-client';

import logger from './logger';
import { Application, ApplicationTypeLess } from '../typings/core-api';

const axios = ax.create();
const restClient = feathers();
const socketClient = feathers();
const authConfig = { storageKey: `ews_${process.env.ENV}` };

// https://github.com/softonic/axios-retry/issues/87
const retryDelay = (retryNumber = 0) => {
  const seconds = Math.pow(2, retryNumber) * 1000;
  const randomMs = 1000 * Math.random();
  return seconds + randomMs;
};

axiosRetry(axios, {
  retries: 3,
  retryDelay,
  // retry on Network Error & 5xx responses
  retryCondition: err => {
    return err?.message === 'Network Error' || axiosRetry.isNetworkError(err);
  },
});

function setLoggerPayload(provider: string) {
  return ctx => {
    logger.rollbar.configure({
      payload: {
        api: {
          provider,
          path: ctx.path,
          method: ctx.method,
          query: ctx.query,
          body: ctx.body,
          id: ctx.id,
          error: ctx.error,
        },
      },
    });
    if (ctx?.error) {
      logger.error(ctx.error);
    }
    return ctx;
  };
}

// REST client
restClient
  // @ts-ignore
  .configure(feathersRestClient(process.env.API).axios(axios))
  .configure(auth(authConfig))
  .hooks({
    before: [setLoggerPayload('rest')],
    error: [setLoggerPayload('rest')],
  });

// socket client
socketClient
  // @ts-ignore
  .configure(
    socketio(
      // @ts-ignore
      io(process.env.WS, {
        path: '/ws/',
        transports: ['websocket'],
        upgrade: false,
      }),
      { timeout: 5000 }
    )
  )
  .configure(auth(authConfig))
  .hooks({
    before: [setLoggerPayload('websocket')],
    error: [setLoggerPayload('websocket')],
  });

socketClient.on('reauthentication-error', logger.error);

const restTs: Application = restClient;
const socketTs: Application = socketClient;
const rest: ApplicationTypeLess = restClient;
const socket: ApplicationTypeLess = socketClient;

export default { rest, socket, restTs, socketTs };
